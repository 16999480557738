import React from "react";

import styles from "components/common/Loader/Loader.module.css";

function Loader() {
  return (
    <div className={styles.container}>
      <img src="/icons/Loader/loaderSmall.gif" alt="loading" />
    </div>
  );
}

export default Loader;
