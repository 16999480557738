import mixpanel from "mixpanel-browser";

import { mixPanelToken, enableMixPanel } from "constants";
import store from "store/store";

mixpanel.init(mixPanelToken, { ignore_dnt: true });

const enableCheck = enableMixPanel;
let orgState = store.getState();
let orgid = orgState?.orgData?.orgid;
let orgName = orgState?.orgData?.orgname;

const updateState = () => {
  const state = store.getState();
  orgid = state?.orgData?.orgid;
  orgName = state?.orgData?.orgname;
};

// Initial update
updateState();

// Subscribe to store updates
store.subscribe(updateState);

let actions = {
  identify: (id) => {
    if (enableCheck) mixpanel.identify(id);
  },
  alias: (id) => {
    if (enableCheck) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (enableCheck) mixpanel.track(name, { ...props, orgid, orgName });
  },
  people: {
    set: (props) => {
      if (enableCheck) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
