import React, { useEffect } from "react";
import ReactJoyride, { ACTIONS, EVENTS } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import tourSteps from "components/common/ProductTour/ProductTourConstants";
import { COLOR } from "constants/themes/colors";
import { actions as tourActions } from "store/Tour/slice";
import checkRolesArr from "utilities/validators/array/checkRolesArr";

const ProductTour = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { run, stepIndex } = useSelector((state) => state.tour);
  // const { addBucketModal, inviteUserModal } = useSelector(
  //   (state) => state.modal,
  // );
  const user = useSelector((state) => state.userData.userData);

  const tourCompleted =
    document.cookie &&
    document.cookie
      .split("; ")
      .find((row) => row.startsWith("tourCompleted="))
      ?.split("=")[1];

  const locale = {
    skip: (
      <span aria-label="Skip" title="This will hide the tour permanently">
        Don&apos;t show this again
      </span>
    ),
    close: <span aria-label="Close">Skip Tour</span>,
  };

  const handleCallBack = (data) => {
    const {
      action,
      index,
      step: { data: { next = {}, previous = {} } = {} },
      type,
    } = data;
    const isPreviousAction = action === "prev";

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      dispatch(tourActions.setStepIndex(nextStepIndex));
    }

    if (type === "step:after" && action === "next") {
      if (index === 0) {
        navigate("/orgDashboard");
      }

      if (index > 1) {
        navigate(isPreviousAction && previous ? previous : next);
      }
    }

    if (type === "step:after" && action === "prev") {
      if (index === tourSteps.length - 1) {
        document.cookie = "tourCompleted=true; max-age=31536000; path=/";
      }

      if (index > 1) {
        if (isPreviousAction && previous) {
          navigate(previous);
        }
      }
    }

    if (action === "close" || action === "reset") {
      dispatch(tourActions.setRun(false));
      dispatch(tourActions.setTourActive(false));
      dispatch(tourActions.setStepIndex(0));
      dispatch(tourActions.setTourState(false));
    }

    if (action === "skip") {
      document.cookie = "tourCompleted=true; max-age=31536000; path=/";
    }
  };

  useEffect(() => {
    if (
      // TODO: Commented to give priority to product tour on onboard steps.
      // !addBucketModal &&
      // !inviteUserModal &&
      checkRolesArr(user.role)
      // window.location.pathname !== "/setup" &&
      // window.location.pathname !== "/integration" &&
      // window.location.pathname !== "/insight"
    ) {
      dispatch(tourActions.setRun(true));
      dispatch(tourActions.setTourActive(true));
    }

    if (!tourCompleted) {
      document.cookie = "tourCompleted=false; max-age=31536000; path=/";
    }
  }, []);

  return (
    <>
      {tourCompleted === "false" && (
        <ReactJoyride
          callback={handleCallBack}
          stepIndex={stepIndex}
          continuous
          run={run}
          locale={locale}
          showProgress={stepIndex !== tourSteps.length - 1}
          showSkipButton
          steps={tourSteps}
          disableBeacon
          spotlightPadding={0}
          disableScrolling
          styles={{
            options: {
              backgroundColor: COLOR.PRIMARY_WHITE,
              arrowColor: COLOR.PRIMARY_MAIN,
              primaryColor: COLOR.PRIMARY_MAIN,
              textColor: COLOR.PRIMARY_WHITE,
            },
            buttonNext: {
              fontSize: "1.4rem",
              outline: "none",
              color: COLOR.PRIMARY_BLACK,
            },
            buttonBack: {
              fontSize: "1.4rem",
              outline: "none",
              color: COLOR.PRIMARY_BLACK,
              marginRight: stepIndex == tourSteps.length - 1 && "auto",
            },
            buttonSkip: {
              fontSize: "1.4rem",
              outline: "none",
              color: COLOR.PRIMARY_BLACK,
            },
            tooltipFooter: {
              flexDirection:
                stepIndex == tourSteps.length - 1 ? "column" : "row",
            },
            tooltip: {
              borderRadius: "8px",
              backgroundColor: COLOR.PRIMARY_MAIN,
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            },
          }}
        />
      )}
    </>
  );
};

export default ProductTour;
