import { COLOR } from "constants/themes/colors";

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

const fontsTemplates = {
  fontSize1: {
    fontSize: "48px",
    fontWeight: fontWeights.medium,
    // lineHeight: "57.6%" /* 27.648px */, // TODO: Copied line height from designs but it's incorrect.
  },
  fontSize2: {
    fontSize: "20px",
    fontWeight: fontWeights.regular,
    // lineHeight: "30%" /* 6px */,  // TODO: Copied line height from designs but it's incorrect.
  },
  fontSize3: {
    fontSize: "16px",
    fontWeight: fontWeights.regular,
    // lineHeight: "24%" /* 3.84px */,  // TODO: Copied line height from designs but it's incorrect.
  },
  fontSize4: {
    fontSize: "20px",
    fontWeight: fontWeights.medium,
    lineHeight: "30px" /* 150% */,
  },
  fontSize5: {
    fontSize: "14px",
    fontWeight: fontWeights.regular,
    lineHeight: "21px" /* 150% */,
  },
  fontSize6: {
    fontSize: "12px",
    fontWeight: fontWeights.regular,
    lineHeight: "18px" /* 150% */,
  },
  fontSize7: {
    fontSize: "28px",
    fontWeight: fontWeights.semiBold,
  },
  fontSize8: {
    fontSize: "14px",
    fontWeight: fontWeights.medium,
  },
  fontSize9: {
    fontSize: "30px",
    fontWeight: fontWeights.semiBold,
  },
  fontSize10: {
    fontSize: "16px",
    fontWeight: fontWeights.medium,
  },
  fontSize11: {
    fontSize: "14px",
    fontWeight: fontWeights.light,
  },
  fontSize12: {
    fontSize: "24px",
    fontWeight: fontWeights.medium,
  },
  fontSize13: {
    fontSize: "56px",
    fontWeight: fontWeights.light,
  },
  fontSize14: {
    fontSize: "28px",
    fontWeight: fontWeights.regular,
  },
  fontSize15: {
    fontSize: "36px",
    fontWeight: fontWeights.medium,
  },
  fontSize16: {
    fontSize: "18px",
    fontWeight: fontWeights.medium,
  },
};

export const typography = {
  fontFamily: "Outfit",
  fontSize: 22.4,
  h2: {
    ...fontsTemplates.fontSize15,
    color: COLOR.PRIMARY_MAIN,
  },
  info1: {
    ...fontsTemplates.fontSize1,
    color: COLOR.PRIMARY_WHITE,
  },
  info2: {
    ...fontsTemplates.fontSize2,
    color: COLOR.PRIMARY_WHITE,
  },
  info3: {
    ...fontsTemplates.fontSize3,
    color: COLOR.PRIMARY_WHITE,
  },
  // TODO: Variants info5, & info6 needs to be removed it's added for select dropdown ( It should style independently)
  info4: {
    ...fontsTemplates.fontSize3,
    color: COLOR.PRIMARY_GREY,
  },
  info5: {
    ...fontsTemplates.fontSize5,
    color: COLOR.SECONDARY_BLACK,
  },
  info6: {
    ...fontsTemplates.fontSize5,
    color: COLOR.PRIMARY_MAIN,
  },
  info7: {
    ...fontsTemplates.fontSize7,
    color: COLOR.PRIMARY_BLACK,
  },
  info8: {
    ...fontsTemplates.fontSize8,
    color: COLOR.SECONDARY_BLACK,
  },
  info9: {
    ...fontsTemplates.fontSize13,
    color: COLOR.PRIMARY_BLACK,
  },
  info10: {
    ...fontsTemplates.fontSize8,
    color: COLOR.PRIMARY_GREY,
  },
  info11: {
    ...fontsTemplates.fontSize3,
    color: COLOR.SECONDARY_BLACK,
  },
  info12: {
    ...fontsTemplates.fontSize4,
    color: COLOR.PRIMARY_BLACK,
  },
  info13: {
    ...fontsTemplates.fontSize2,
    color: COLOR.PRIMARY_BLACK,
  },
  info14: {
    ...fontsTemplates.fontSize14,
    color: COLOR.PRIMARY_BLACK,
  },
  info15: {
    ...fontsTemplates.fontSize16,
    color: COLOR.PRIMARY_MAIN,
  },

  // ----
  label1: {
    ...fontsTemplates.fontSize4,
    color: COLOR.SECONDARY_BLACK,
  },
  label2: {
    ...fontsTemplates.fontSize2,
    color: COLOR.PRIMARY_GREY,
  },
  label3: {
    ...fontsTemplates.fontSize4,
    color: COLOR.PRIMARY_GREY,
  },
  label4: {
    ...fontsTemplates.fontSize7,
    color: COLOR.PRIMARY_BLACK,
  },
  label5: {
    ...fontsTemplates.fontSize2,
    color: COLOR.PRIMARY_MAIN,
  },
  links1: {
    ...fontsTemplates.fontSize5,
  },
  links2: {
    ...fontsTemplates.fontSize3,
    color: COLOR.PRIMARY_MAIN,
  },
  helperText1: {
    ...fontsTemplates.fontSize6,
    color: COLOR.PRIMARY_GREY,
  },
  helperText2: {
    ...fontsTemplates.fontSize6,
    color: COLOR.PRIMARY_MAIN,
  },
  cardLabel1: {
    ...fontsTemplates.fontSize10,
    color: COLOR.PRIMARY_BLACK,
  },
  cardLabel2: {
    ...fontsTemplates.fontSize11,
    color: COLOR.PRIMARY_GREY,
  },
  cardLabel3: {
    ...fontsTemplates.fontSize12,
    color: COLOR.PRIMARY_MAIN,
  },
  cardLabel4: {
    ...fontsTemplates.fontSize11,
    color: COLOR.SECONDARY_BLACK,
  },

  // Error
  error1: {
    ...fontsTemplates.fontSize5,
    color: COLOR.ERROR_MAIN,
  },
};
