import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CancelIconOutlined from "@mui/icons-material/HighlightOffOutlined";

import { ButtonWrapperLowKey } from "components/common/Button/ButtonWrapper";
import styles from "components/common/Toast/Toast.module.css";
import { actions as toastPopupActions } from "store/ToastPopup/slice";

const Toast = () => {
  const dispatch = useDispatch();
  const toastlist = useSelector((state) => state.toastPopup.list);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastlist.length) {
        dispatch(toastPopupActions.deleteToast(toastlist[0].id));
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [toastlist]);

  return (
    <div className={styles.container}>
      {toastlist.map((toast, i) => (
        <div
          key={i}
          className={styles.notification}
          style={{
            backgroundColor: toast.backgroundColor,
            borderColor: toast.borderColor,
          }}
        >
          <div className={styles.flexCol}>
            <div
              className={styles.subCont}
              style={{ color: toast.headingColor }}
            >
              <span className={styles.title}>{toast.title}</span>

              <CancelIconOutlined
                sx={{ cursor: "pointer", fontSize: 20 }}
                onClick={() =>
                  dispatch(toastPopupActions.deleteToast(toast.id))
                }
              />
            </div>
            <span
              className={styles.description}
              style={{ color: toast.descriptionColor }}
            >
              {toast.description}
            </span>
          </div>
        </div>
      ))}
      {toastlist.length > 0 && (
        <div className={styles.btnContainer}>
          <ButtonWrapperLowKey
            onClick={() => dispatch(toastPopupActions.deleteAllToast())}
          >
            Clear All
          </ButtonWrapperLowKey>
        </div>
      )}
    </div>
  );
};

export default Toast;
