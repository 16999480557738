import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const RedirectTo = () => {
  const navigate = useNavigate();

  const redirectUrl = window.location.href;

  useEffect(() => {
    navigate(`/login?redirecturl=${redirectUrl}`);
  }, []);
};

function ProtectedRoutes() {
  const authToken = localStorage.getItem("token");

  return authToken ? <Outlet /> : <RedirectTo />;
}

export default ProtectedRoutes;
