import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orgname: localStorage.getItem("orgname") || "",
  orgid: localStorage.getItem("orgid") || "",
  orgconfig: localStorage.getItem("orgconfig") || "",
};

const slice = createSlice({
  name: "orgData",
  initialState,
  reducers: {
    setOrgName: (state, action) => {
      state.orgname = action.payload;
    },
    setOrgId: (state, action) => {
      state.orgid = action.payload;
    },
    setOrgConfig: (state, action) => {
      state.orgconfig = action.payload;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
