import axios from "axios";

const REACT_BACKEND_URL = "/api"; // backend

const REACT_PRICING_URL = "/pricing/api"; // pricing

export const REACT_DOCU_URL = "/docu#/"; // documentation

const restClient = axios.create({
  baseURL: REACT_BACKEND_URL,
});

export const restClientPricing = axios.create({
  baseURL: REACT_PRICING_URL,
});

export default restClient;
