import { createTheme } from "@mui/material/styles";
import { COLOR } from "constants/themes/colors";
import { typography } from "constants/themes/typography";

const themeOptions = {
  palette: {
    primary: {
      main: COLOR.PRIMARY_MAIN,
      light: COLOR.PRIMARY_WHITE,
      background: COLOR.SECONDARY_WHITE,
      border: COLOR.GREY_200,
    },
    text: {
      primary: COLOR.SECONDARY_BLACK,
      primaryGrey: COLOR.PRIMARY_GREY,
      secondary: COLOR.PRIMARY_WHITE,
    },
    table: {
      background: COLOR.PRIMARY_WHITE,
      title1: COLOR.PRIMARY_BLACK,
      title2: COLOR.PRIMARY_GREY,
      tableBodyNormal: COLOR.PRIMARY_BLACK,
      tableBodyBold: COLOR.SECONDARY_BLACK,
      pagination: COLOR.PAGINATION_TEXT,
      headerBackground: COLOR.BACKGROUND_GREY,
    },
    tableFiler: {
      heading: COLOR.GREY_300,
    },
  },
  typography,
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "authGradient" },
          style: {
            textTransform: "none",
            borderRadius: "24px",
            background: `var(--secondary-gradient)`,
            fontSize: typography.label1.fontSize,
            fontWeight: typography.label1.fontWeight,
            color: COLOR.PRIMARY_WHITE,
            width: "100%",
            cursor: "pointer",
            "&:disabled": {
              opacity: 0.7,
              cursor: "not-allowed",
              color: COLOR.PRIMARY_WHITE,
            },
          },
        },
        {
          props: { variant: "outlinedButton" },
          style: {
            textTransform: "none",
            borderRadius: "24px",
            background: COLOR.PRIMARY_WHITE,
            fontSize: typography.cardLabel1.fontSize,
            fontWeight: typography.label1.fontWeight,
            color: COLOR.PRIMARY_MAIN,
            width: "100%",
            cursor: "pointer",
            border: `1px solid ${COLOR.PRIMARY_MAIN}`,
            "&:disabled": {
              opacity: 0.7,
              cursor: "not-allowed",
              color: COLOR.PRIMARY_MAIN_LIGHTER,
            },
          },
        },
      ],
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          "&  .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
            color: COLOR.PRIMARY_MAIN,
            fontSize: typography.info6.fontSize,
          },
          "&  .MuiSvgIcon-root": {
            fontSize: typography.label4.fontSize,
            fill: COLOR.PRIMARY_LIGHT,
            borderRadius: "50%",
            border: `1px solid ${COLOR.PRIMARY_MAIN}`,
          },
          "&  .MuiStepIcon-root.Mui-active": {
            fontSize: "3.4rem",
            padding: "0.4rem",
            fill: COLOR.PRIMARY_MAIN,
            border: `1px solid ${COLOR.PRIMARY_MAIN}`,
          },
          "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
            color: COLOR.SECONDARY_BLACK,
            fontSize: typography.helperText1.fontSize,
            marginTop: "0.4rem",
          },
          "& .MuiStepConnector-line.MuiStepConnector-lineHorizontal": {
            border: `1.5px solid ${COLOR.PRIMARY_MAIN}`,
          },
          "& .MuiStepIcon-root.Mui-completed": {
            fill: COLOR.PRIMARY_MAIN,
          },
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "integrationCards" },
          style: {
            borderRadius: "6px",
            border: `1px solid ${COLOR.GREY_200}`,
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.20)",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "3px 3px 4px 0px rgba(0, 0, 0, 0.25)",
              border: `1.2px solid ${COLOR.PRIMARY_MAIN_LIGHTER}`,
            },
          },
        },
      ],
    },
    MuiLinearProgress: {
      variants: [
        {
          props: { variant: "determinate" },
          style: {
            height: "14px",
            borderRadius: "3px",
            boxShadow: "1px 1px 10px 0px rgba(0, 0, 0, 0.15)",
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              background:
                "linear-gradient(90deg, #09A9F9 0%, #04CBE0 52.65%, #00E3CE 100%)",
            },
          },
        },
      ],
    },
    MuiAvatar: {
      variants: [
        {
          props: { variant: "avatar" },
          style: {
            width: "28px",
            height: "28px",
            backgroundColor: COLOR.PRIMARY_WHITE,
            color: COLOR.PRIMARY_MAIN,
            cursor: "pointer",
          },
        },
      ],
    },
  },
};

export const theme = createTheme(themeOptions);
