import styles from "components/common/Button/ButtonWrapper.module.css";

export const ButtonWrapperDefault = (props) => {
  return (
    <button
      type={props.type}
      className={`${styles.buttonDefault} ${
        props.disabled ? styles.disabled : ""
      } }`}
      id={props.id}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export const ButtonWrapperDanger = (props) => {
  return (
    <button
      type={props.type}
      className={styles.buttonDanger}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export const ButtonWrapperCancel = (props) => {
  return (
    <button
      type={props.type}
      className={styles.buttonCancel}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export const ButtonWrapperToggle = (props) => {
  return (
    <button
      type={props.type}
      className={styles.buttonToggle}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export const ButtonWrapperLowKey = (props) => {
  return (
    <button
      type={props.type}
      className={styles.buttonLowKey}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export const ButtonWrapperTransparent = (props) => {
  return (
    <button
      type={props.type}
      className={styles.buttonTransparent}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default ButtonWrapperDefault;
