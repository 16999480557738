import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import RocketOutlinedIcon from "@mui/icons-material/RocketOutlined";
import { useTranslation } from "react-i18next";

import styles from "components/layouts/Sidebar/Sidebar.module.css";
import CustomTooltip from "components/common/CustomToolTip/CustomTooltip";
import checkRolesArr from "utilities/validators/array/checkRolesArr";
import { actions as sidebarActions } from "store/Sidebar/slice";
import { enableTools } from "constants";
import { Mixpanel } from "services/Mixpanel";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.sidebar.isOpen);
  const productTourActiveStep = useSelector(
    (state) => state.sidebar.productTourActiveStep,
  );
  const activePath = window.location.pathname;
  const user = useSelector((state) => state.userData.userData);
  const userName = user.name;
  const userEmail = user.email;
  const userRole = user.role;
  const breadcrums = useSelector((state) => state.sidebar.breadcrums);
  const accountSetup = JSON.parse(localStorage.getItem("account_Setup"));
  const isSetUpCompleted = accountSetup?.completed;
  const { t } = useTranslation();

  const [navLinksAll, setNavLinksAll] = useState([
    {
      name: "Organization",
      route: "/orgDashboard",
      display: checkRolesArr(userRole),
      active: false,
      icon: "/icons/Sidebar/dashboard.svg",
    },
    {
      name: "Insight",
      route: "/insight",
      display: checkRolesArr(userRole),
      active: false,
      icon: "/icons/Sidebar/report.svg",
    },
    {
      name: "Budget",
      route: "/budgetApprovals",
      display: checkRolesArr(userRole),
      active: false,
      icon: "/icons/Sidebar/budget.svg",
    },
    {
      name: "Optimization",
      route: "/optimization",
      display: checkRolesArr(userRole),
      active: false,
      icon: "/icons/Sidebar/optimization.svg",
    },
    {
      name: "Integration",
      route: "/integration",
      display: checkRolesArr(userRole),
      active: false,
      icon: "/icons/Sidebar/costSource.svg",
    },
    {
      name: "My Buckets",
      route: "/myBuckets",
      display: true,
      active: false,
      icon: "/icons/Sidebar/bucket.svg",
    },
    {
      name: "Administration",
      route: "/administration",
      display: checkRolesArr(userRole),
      active: false,
      icon: "/icons/Sidebar/administration.svg",
    },
    {
      name: "Tools",
      route: "/tools",
      display: checkRolesArr(userRole) && enableTools,
      active: false,
      icon: "/icons/Sidebar/tools.svg",
    },
    {
      name: "Onboard",
      route: "/setup",
      display: checkRolesArr(userRole) && !isSetUpCompleted,
      active: false,
      icon: <RocketOutlinedIcon sx={{ color: "#fff" }} />,
      position: "bottom",
    },
  ]);

  const getInitial = (value) => value.charAt(0).toUpperCase();

  const onClickHandler = (route) => {
    navigate(route);
    Mixpanel.track(`visited ${route}`);
  };

  const onTourClick = (route) => {
    onClickHandler(route);
    dispatch(sidebarActions.upgradeActiveStepCount(1));
  };

  const logoutClickHandler = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
    Mixpanel.track("Logout clicked");
  };

  const formatTitle = (title) =>
    title === "Budget" ? "Budget Approvals" : title;

  useEffect(() => {
    if (breadcrums[0]?.name) {
      const newNavLinksAll = navLinksAll.map((link) => {
        if (window.location.pathname.includes("integration")) {
          dispatch(
            sidebarActions.updateBreadcrums([
              { name: "Integration", link: "/integration" },
              { name: "Consumption Pricing" },
            ]),
          );
          return link.name === "Integration"
            ? { ...link, active: true }
            : { ...link, active: false };
        } else if (window.location.pathname.includes("setup")) {
          return link.name === "Onboard"
            ? { ...link, active: true }
            : { ...link, active: false };
        } else if (formatTitle(link.name) === breadcrums[0].name) {
          return { ...link, active: true };
        } else {
          return { ...link, active: false };
        }
      });

      setNavLinksAll(newNavLinksAll);
    }
  }, [breadcrums[0]?.name]);

  return (
    <div
      className={`${styles.containerOpen}  ${
        !isSidebarOpen && styles.containerClose
      }`}
    >
      <div
        className={`${styles.sidebarOpen} ${
          !isSidebarOpen && styles.sidebarClose
        }`}
      >
        <div className={styles.logoCont}>
          <img
            className={styles.logoImg}
            src="/icons/Sidebar/logoImg.svg"
            alt="img"
          />
          {isSidebarOpen && (
            <img
              className={styles.logoText}
              src="/icons/Sidebar/logoText.svg"
              alt="img"
            />
          )}
        </div>
        <div className={styles.linkCont}>
          {navLinksAll
            .filter((link) => link.position !== "bottom")
            .map((link, index) => {
              return (
                link.display && (
                  <div
                    key={index}
                    className={`${
                      (isSidebarOpen && styles.navLinksContOpen) ||
                      styles.navLinksContClose
                    } ${link.active && styles.linkActive}`}
                    id={link.name === "My Buckets" ? "myBuckets" : link.name}
                    onClick={() => onClickHandler(link.route)}
                    role="button"
                    tabIndex={0}
                  >
                    <img className={styles.linkIcon} src={link.icon} alt="" />
                    {isSidebarOpen && (
                      <span className={styles.link}>
                        {t(`SIDEBAR_PAGE_TITLES.${link.name}`)}
                      </span>
                    )}
                  </div>
                )
              );
            })}
        </div>
        <div className={styles.bottomLinksCont}>
          {navLinksAll
            .filter((link) => link.position === "bottom")
            .map((link, index) => {
              return (
                link.display && (
                  <div
                    key={index}
                    className={`${
                      (isSidebarOpen && styles.navLinksContOpen) ||
                      styles.navLinksContClose
                    } ${link.active && styles.linkActive}`}
                    onClick={() => onClickHandler(link.route)}
                    role="button"
                    tabIndex={0}
                  >
                    {typeof link.icon == "string" ? (
                      <img className={styles.linkIcon} src={link.icon} alt="" />
                    ) : (
                      <div className={styles.linkIcon}>{link.icon}</div>
                    )}
                    {isSidebarOpen && (
                      <span className={styles.link}>
                        {t(`SIDEBAR_PAGE_TITLES.${link.name}`)}
                      </span>
                    )}
                    {link.name === "Onboard" &&
                      !link.active &&
                      !isSetUpCompleted &&
                      !accountSetup.tourCompleted &&
                      productTourActiveStep === 0 &&
                      activePath !== "/setup" && (
                        <Box
                          className={!isSidebarOpen && `${styles.beaconIcon}`}
                        >
                          <CustomTooltip title="Complete account setup to maximize your app experience.">
                            <img
                              src="/icons/Loader/Beacon_Alert.svg"
                              alt="loading"
                              onClick={() => onTourClick("/setup")}
                            />
                          </CustomTooltip>
                        </Box>
                      )}
                  </div>
                )
              );
            })}
        </div>
        <div className={styles.userOuterCont}>
          <div
            className={styles.toggleBtn}
            onClick={() => {
              dispatch(sidebarActions.toggleSidebar());
            }}
            role="button"
            tabIndex={0}
          >
            <img
              className={!isSidebarOpen ? styles.rotate : ""}
              src="/icons/Sidebar/leftArrow.svg"
              alt=""
            />
          </div>
          <div className={styles.divider}>
            <div>&nbsp;</div>
          </div>
          {isSidebarOpen ? (
            <div className={styles.userActions}>
              <div
                className={`${styles.userCont} ${
                  window.location.pathname === "/userDetails" &&
                  styles.linkActive
                }`}
                onClick={() => {
                  onClickHandler("/userDetails");
                }}
                role="button"
                tabIndex={0}
              >
                <div className={styles.userIcon}>
                  {getInitial(userName || userEmail)}
                </div>
                <div className={styles.userNameCont}>
                  <span className={styles.userName}>{userName}</span>
                  <span className={styles.userEmail}>{userEmail}</span>
                </div>
              </div>
              <div
                onClick={logoutClickHandler}
                className={styles.logoutCont}
                role="button"
                tabIndex={0}
              >
                <img src="/icons/Sidebar/logout.svg" alt="" />
                <span>{t(`SIDEBAR_PAGE_TITLES.Logout`)}</span>
              </div>
            </div>
          ) : (
            <>
              <div
                className={`${styles.userCont} ${
                  window.location.pathname === "/userDetails" &&
                  styles.linkActive
                }`}
              >
                <div
                  onClick={() => {
                    onClickHandler("/userDetails");
                  }}
                  className={styles.userAboutCont}
                  role="button"
                  tabIndex={0}
                >
                  <div className={styles.userIcon}>
                    {getInitial(userName || userEmail)}
                  </div>
                </div>
              </div>
              <div
                onClick={logoutClickHandler}
                className={styles.logoutCont}
                role="button"
                tabIndex={0}
              >
                <img src="/icons/Sidebar/logout.svg" alt="" />
              </div>
            </>
          )}
        </div>
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default Sidebar;
