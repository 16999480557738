export const COLOR = {
  PRIMARY_MAIN: "#23B4C6",
  PRIMARY_LIGHT: "#DEF4F7",
  PRIMARY_MAIN_LIGHTER: "#9CDDE5",
  PRIMARY_DARK: "#067793",
  BLUE_50: "#E6FBFB",
  BLUE_100: "#94DBE4",
  BLUE_200: "#00CFE5",
  BLUE_300: "#034768",
  BLUE_400: "#003F75",
  BLUE_500: "#027497",
  BLUE_600: "#082D68",
  SECONDARY_MAIN: "",
  SECONDARY_LIGHT: "",
  SECONDARY_DARK: "",

  PRIMARY_WHITE: "#FFFFFF",
  SECONDARY_WHITE: "#F5F5F5",
  GREY_300: "#BDBDBD",
  GREY_200: "#DADDE2",
  GREY_100: "#F4F7FA",

  PRIMARY_BLACK: "#0d0d0d",
  SECONDARY_BLACK: "#363636",
  PRIMARY_GREY: "#858585",
  BACKGROUND_GREY: "#f2f8fb",
  PAGINATION_TEXT: "#646464",

  GREEN: "#00E5CF",
  GREEN_LIGHT: "#E4F6EC",
  SUCESS_MAIN: "#0C9A4A",

  YELLOW_100: "#FAF9D3",
  YELLOW_200: "#BF9911",

  ERROR_MAIN: "#E82C2C",
  RED_100: "#F5DFDF",
  RED_200: "#EFCDCD",
  RED_300: "#DD5246",

  // Chart colors
  CHART_COLOR_1: "#74c476",
  CHART_COLOR_2: "#1F77B4",
  CHART_COLOR_3: "#8073ac",
  CHART_COLOR_4: "#ff7f0e",
  CHART_COLOR_5: "#66c2a4",
  // 5

  CHART_COLOR_6: "#ffd92f",
  CHART_COLOR_7: "#d53e4f",
  CHART_COLOR_8: "#41B6C4",
  CHART_COLOR_9: "#f46d43",
  CHART_COLOR_10: "#92c5de",
  // 10

  CHART_COLOR_11: "#1D91C0",
  CHART_COLOR_12: "#fdae61",
  CHART_COLOR_13: "#8DD3C7",
  CHART_COLOR_14: "#fee08b",
  CHART_COLOR_15: "#C7E9C0",
  // 15

  CHART_COLOR_16: "#e78ac3",
  CHART_COLOR_17: "#8da0cb",
  CHART_COLOR_18: "#542788",
  CHART_COLOR_19: "#b2abd2",
  CHART_COLOR_20: "#fcc5c0",
  // 20
};

export const CHARTS_COLOR_ARRAY = {
  CHARTS_COLOR_ARRAY_1: [
    COLOR.CHART_COLOR_1,
    COLOR.CHART_COLOR_2,
    COLOR.CHART_COLOR_3,
    COLOR.CHART_COLOR_4,
    COLOR.CHART_COLOR_5,
    COLOR.CHART_COLOR_6,
    COLOR.CHART_COLOR_7,
    COLOR.CHART_COLOR_8,
    COLOR.CHART_COLOR_9,
    COLOR.CHART_COLOR_10,
    COLOR.CHART_COLOR_11,
    COLOR.CHART_COLOR_12,
    COLOR.CHART_COLOR_13,
    COLOR.CHART_COLOR_14,
    COLOR.CHART_COLOR_15,
    COLOR.CHART_COLOR_16,
    COLOR.CHART_COLOR_17,
    COLOR.CHART_COLOR_18,
    COLOR.CHART_COLOR_19,
    COLOR.CHART_COLOR_20,
  ],
};
