import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import Toast from "components/common/Toast/Toast";
import store from "store/store";
import App from "App";
import "index.css";
import {
  enableSSO,
  googleSSOClientID,
  microsoftSSOClientID,
  microsoftSSORediectURL,
  microsoftSSOAuthorityURL,
} from "constants";
import "services/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

const msalConfiguration = {
  auth: {
    authority: microsoftSSOAuthorityURL,
    clientId: microsoftSSOClientID,
    redirectUri: microsoftSSORediectURL,
  },
};
const pca = new PublicClientApplication(msalConfiguration);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      {enableSSO ? (
        <GoogleOAuthProvider clientId={googleSSOClientID || ""}>
          <MsalProvider instance={pca}>
            <App />
          </MsalProvider>
        </GoogleOAuthProvider>
      ) : (
        <App />
      )}

      <Toast />
    </React.StrictMode>
  </Provider>,
);
