import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  run: false,
  stepIndex: 0,
  tourActive: false,
  tourState: true,
};

const slice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setRun: (state, action) => {
      state.run = action.payload;
    },
    setStepIndex: (state, action) => {
      state.stepIndex = action.payload;
    },
    setTourActive: (state, action) => {
      state.tourActive = action.payload;
    },
    setTourState: (state, action) => {
      state.tourState = action.payload;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
