import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import Router from "routes/routes";
import { theme } from "constants";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
