import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addBucketModal: false,
  inviteUserModal: false,
};

const slice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setAddBucketModal: (state, action) => {
      state.addBucketModal = action.payload;
    },
    setInviteUserModal: (state, action) => {
      state.inviteUserModal = action.payload;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
