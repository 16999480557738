/* eslint-disable no-undef */
const reactENV = process.env.REACT_APP_ENV;
const isProdEnv = reactENV === "production" ? true : false;

const showTasksTab = /true/.test(process.env.REACT_APP_ENABLE_TASK);
const showChatPopup = /true/.test(process.env.REACT_APP_ENABLE_CHAT);
const enableTools = /true/.test(process.env.REACT_APP_ENABLE_TOOLS);
const enableSSO = /true/.test(process.env.REACT_APP_ENABLE_SSO);
const enableMixPanel = /true/.test(process.env.REACT_APP_ENABLE_MIXPANEL);

const googleSSOClientID = process.env.REACT_APP_SSO_GOOGLE_CLIENT_ID;

const microsoftSSOClientID = process.env.REACT_APP_SSO_MICROSOFT_CLIENT_ID;
const microsoftSSORediectURL = process.env.REACT_APP_SSO_MICROSOFT_REDIRECT_URL;
const microsoftSSOAuthorityURL = "https://login.microsoftonline.com/common";

const mixPanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

export {
  isProdEnv,
  reactENV,
  showTasksTab,
  showChatPopup,
  enableTools,
  enableSSO,
  enableMixPanel,
  googleSSOClientID,
  microsoftSSOClientID,
  microsoftSSORediectURL,
  microsoftSSOAuthorityURL,
  mixPanelToken,
};
