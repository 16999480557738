import { configureStore } from "@reduxjs/toolkit";

import toastPopupReducer from "store/ToastPopup/slice";
import sidebarReducer from "store/Sidebar/slice";
import userDataReducer from "store/UserData/slice";
import orgDataReducer from "store/OrgData/slice";
import bucketNodeExpanderReducer from "store/BucketNodeExpander/slice";
import modalReducer from "store/NewUser/slice";
import tourReducer from "store/Tour/slice";

const store = configureStore({
  reducer: {
    toastPopup: toastPopupReducer,
    sidebar: sidebarReducer,
    userData: userDataReducer,
    orgData: orgDataReducer,
    bucketNodeExpander: bucketNodeExpanderReducer,
    modal: modalReducer,
    tour: tourReducer,
  },
});

export default store;
