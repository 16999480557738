const date = (dateString) => {
  if (!dateString) return "Never";

  const date = new Date(dateString);

  const yearMonthDate = date.toLocaleDateString(navigator.language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
  });

  const time = date.toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: "UTC",
  });

  return `${yearMonthDate} ${time} UTC`;
};

export default date;
