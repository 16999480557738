import { Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";

import ProtectedRoutes from "routes/ProtectedRoutes";
import PublicRoutes from "routes/PublicRoutes";
import Sidebar from "components/layouts/Sidebar/Sidebar";
import Loader from "components/common/Loader/Loader";
import ProductTour from "components/common/ProductTour/ProductTour";
import {
  authRoutes,
  protectedRoutesWithLayout,
  protectedRoutesWithoutLayout,
  openRoutes,
} from "routes/routeConstants";
import ChatPopup from "pages/ChatPopup/ChatPopup";
import { showChatPopup } from "constants";

const Router = () => {
  const { tourState } = useSelector((state) => state.tour);
  return (
    <Routes>
      <Route element={<PublicRoutes />}>
        {authRoutes.map((route) => (
          <Route
            key={route.title}
            path={route.path}
            element={
              <Suspense
                fallback={
                  <div className="loaderCont">
                    <Loader />
                  </div>
                }
              >
                {route.element}
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route element={<ProtectedRoutes />}>
        {[
          ...protectedRoutesWithLayout.map((route) => (
            <Route
              key={route.title}
              path={route.path}
              element={
                <Sidebar key={"sidebar" + route.title}>
                  <Suspense
                    fallback={
                      <div className="loaderCont">
                        <Loader />
                      </div>
                    }
                  >
                    {route.element}
                    {tourState && <ProductTour />}
                    {showChatPopup && <ChatPopup />}
                  </Suspense>
                </Sidebar>
              }
            />
          )),
          ...protectedRoutesWithoutLayout.map((route) => (
            <Route
              key={route.title}
              path={route.path}
              element={
                <Suspense
                  fallback={
                    <div className="loaderCont">
                      <Loader />
                    </div>
                  }
                >
                  {route.element}
                </Suspense>
              }
            />
          )),
        ]}
      </Route>
      <Route>
        {openRoutes.map((route) => (
          <Route
            key={route.title}
            path={route.path}
            element={
              <Suspense
                fallback={
                  <div className="loaderCont">
                    <Loader />
                  </div>
                }
              >
                {route.element}
              </Suspense>
            }
          />
        ))}
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default Router;
