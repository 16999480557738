import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
};

const slice = createSlice({
  name: "toastPopup",
  initialState,
  reducers: {
    addToast: (state, action) => {
      let toastProperties = null;

      switch (action.payload.type) {
        case "success":
          toastProperties = {
            id: state.list.length + 1,
            title: action.payload.title,
            description: action.payload.description,
            backgroundColor: "#FFFFFF",
            borderColor: "#0C9A4A",
            headingColor: "#0C9A4A",
            descriptionColor: "#0C9A4A",
          };
          break;

        case "error":
          toastProperties = {
            id: state.list.length + 1,
            title: action.payload.title,
            description: action.payload.description,
            backgroundColor: "#FFFFFF",
            borderColor: "#E82C2C",
            headingColor: "#E82C2C",
            descriptionColor: "#E82C2C",
          };
          break;

        case "warning":
          toastProperties = {
            id: state.list.length + 1,
            title: action.payload.title,
            description: action.payload.description,
            backgroundColor: "#FFFFFF",
            borderColor: "#BF9911",
            headingColor: "#BF9911",
            descriptionColor: "#BF9911",
          };
          break;

        default:
          toastProperties = {
            id: state.list.length + 1,
            title: action.payload.title,
            description: action.payload.description,
            backgroundColor: "#FFFFFF",
            borderColor: "#858585",
            headingColor: "#858585",
            descriptionColor: "#858585",
          };
      }
      state.list.push(toastProperties);
    },
    deleteToast: (state, action) => {
      const id = action.payload;
      state.list = state.list.filter((toast) => toast.id !== id);
    },
    deleteAllToast: (state) => {
      state.list = [];
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
