import React from "react";
import { Box, Tooltip } from "@mui/material";

import { COLOR } from "constants/themes/colors";
import { typography } from "constants/themes/typography";

const CustomTooltip = ({ title, children, placement }) => {
  return (
    <Tooltip
      placement={placement}
      title={
        title ? (
          <>
            <div>{title}</div>
          </>
        ) : null
      }
      arrow={true}
      componentsProps={{
        tooltip: {
          sx: {
            color: COLOR.PRIMARY_BLACK,
            fontSize: typography.info5.fontSize,
            fontWeight: typography.info5.fontWeight,
            backgroundColor: COLOR.PRIMARY_LIGHT,
            padding: "8px 16px",
            borderRadius: "8px",
            border: `2px solid ${COLOR.PRIMARY_MAIN}`,
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            "& .MuiTooltip-arrow": {
              color: COLOR.PRIMARY_MAIN,
              fontSize: "2rem",
            },
          },
        },
      }}
    >
      <Box
        sx={{ cursor: "pointer", display: "inline-flex", alignItems: "center" }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default CustomTooltip;
