import { useState } from "react";
import { Tooltip } from "@mui/material";
import ForumIcon from "@mui/icons-material/Forum";
// import Help from "@mui/icons-material/Help";

import ChatModalForm from "pages/ChatPopup/ChatModalForm";
import styles from "pages/ChatPopup/ChatPopup.module.css";

const ChatPopup = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className={styles.chatPopupWrapper}>
      {modalOpen && (
        <ChatModalForm
          toggleModal={toggleModal}
          loading={loading}
          setLoading={setLoading}
          chatHistory={chatHistory}
          setChatHistory={setChatHistory}
        />
      )}

      <Tooltip title="Ask a question">
        {/* <Help color="primary" fontSize="large" onClick={toggleModal} /> */}
        <ForumIcon color="primary" fontSize="large" onClick={toggleModal} />
      </Tooltip>
    </div>
  );
};

export default ChatPopup;
