import { Box, Modal } from "@mui/material";
import styles from "components/common/ChartExpandView/ChartExpandView.module.css";

function ChartExpandView({ children, onClose }) {
  return (
    <Modal open={true} onClose={onClose}>
      <Box className={styles.content}>{children}</Box>
    </Modal>
  );
}

export default ChartExpandView;
