import { Stack, Typography } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

const buttonStyles = {
  fontSize: "1.4rem",
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
};

const tourSteps = [
  {
    target: "body",
    content: (
      <Stack direction="column" alignItems={"center"}>
        <Typography variant="h4">Welcome to Clofio!</Typography>
        <RocketLaunchIcon sx={{ margin: "18px 0" }} fontSize="large" />
        <Typography>
          We&apos;re excited to help you get the most out of your cloud
          resources. Let’s take a quick tour to get you started.
        </Typography>
      </Stack>
    ),
    disableBeacon: true,
    placement: "center",
  },
  {
    target: "#Organization",
    content:
      "Visualize your entire organization’s cloud resources. This tree structure helps you understand the hierarchy and distribution of your cloud assets, making management more intuitive and efficient.",
    placement: "right",
  },
  {
    target: "#pt_add_cost_bucket_button",
    content:
      "Organize your expenses by creating cost buckets. Use this button to categorize your spending and keep track of your budget allocations.",
    data: {
      next: "/insight",
    },
  },
  {
    target: "#Insight",
    content:
      "Gain valuable insights into your cloud spending with our interactive dashboard charts. These visualizations help you monitor and analyze your costs, usage patterns, and trends at a glance.",
    placement: "right",
    data: {
      next: "/budgetApprovals",
      previous: "/orgDashboard",
    },
  },
  {
    target: "#Budget",
    content:
      "Set up budget plans and manage approvals efficiently. Use this feature to control your spending, track budget adherence, and ensure all expenses are pre-approved to avoid overspending.",
    placement: "right",
    data: {
      next: "/optimization",
      previous: "/budgetApprovals",
    },
  },
  {
    target: "#Optimization",
    content:
      "Optimize your cloud spending with our cost recommendations. Click here to find suggestions on how to reduce costs, eliminate waste, and improve the efficiency of your cloud resources.",
    placement: "right",
    data: {
      next: "/integration",
      previous: "/budgetApprovals",
    },
  },
  {
    target: "#Integration",
    content:
      "Easily integrate new cloud providers into your management system. This section allows you to add and list all your cloud accounts, ensuring a comprehensive view of your cloud environment.",
    placement: "right",
  },
  {
    target: "#pt_add_cost_source_button",
    content:
      "Connect your cloud accounts seamlessly. Click here to add new integrations and start tracking your costs and usage across different cloud providers.",
    placement: "left",
    data: {
      next: "/myBuckets",
      previous: "/integration",
    },
  },
  {
    target: "#myBuckets",
    content:
      "View and manage all your cost buckets & shared cost buckets in one place. This listing provides a detailed overview of all categories, helping you keep track of your spending allocations and bucket owners.",
    placement: "right",
    data: {
      next: "/administration",
      previous: "/integration",
    },
  },
  {
    target: "#Administration",
    content:
      "Manage all administrative settings from one central location. Use this section to configure user roles, permissions, and overall application settings to maintain control and security.",
    placement: "right",
  },
  {
    target: "#pt_invite_user_button",
    content:
      "Easily expand your team by inviting new users. Click this button to send invitations and collaborate with your team members on managing cloud costs.",
    placement: "left",
    data: {
      next: "/orgDashboard",
      previous: "/administration",
    },
  },
  {
    target: "body",
    content: (
      <Stack direction="column" alignItems={"center"}>
        <Typography variant="h4">You&apos;re all set!</Typography>
        <DoneAllIcon sx={{ margin: "18px 0" }} fontSize="large" />
        <Typography>
          Now you’re ready to explore Clofio on your own. If you prefer not to
          see this tour again, click the button below. Enjoy managing your cloud
          resources more efficiently!
        </Typography>
      </Stack>
    ),
    placement: "center",
    locale: {
      last: <button style={buttonStyles}>Show tour next time</button>,
      back: <button style={buttonStyles}>Got it! Dont show again</button>,
    },
  },
];

export default tourSteps;
