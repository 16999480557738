const checkRolesArr = (userRoles) => {
  const rolesArr = ["org_admin", "org_manager", "org_viewer"];

  let check = false;
  userRoles?.forEach((role) => {
    if (rolesArr.includes(role)) {
      check = true;
      return;
    }
  });
  return check;
};

export default checkRolesArr;
