import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isExpanded: false,
  // To show chart expanded view.
  isActiveChart: null,
  activeComponent: undefined,
};

const slice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toogleExpandedView: (state) => {
      state.isExpanded = !state.isExpanded;
    },
    setExpandedView: (state, action) => {
      state.isActiveChart = action.payload;
    },
    collapseExpandedView: (state) => {
      state.isActiveChart = null;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

// TODO: Temporarily added chart expand states here i.e. isActiveChart,activeComponent,
// later we need to give this store a common name to store all general states of the app.
