import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

import checkRolesArr from "utilities/validators/array/checkRolesArr";

function RedirectTo() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userData.userData);

  useEffect(() => {
    if (!userData.role) return navigate("/organizations");

    if (checkRolesArr(userData.role)) navigate("/orgDashboard");
    else navigate("/myBuckets");
  }, []);
}

function PublicRoutes() {
  const authToken = localStorage.getItem("token");

  return authToken ? <RedirectTo /> : <Outlet />;
}

export default PublicRoutes;
