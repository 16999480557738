import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: true,
  breadcrums: JSON.parse(localStorage.getItem("breadcrumbs")) || [],
  productTourActiveStep: 0,
};

const slice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.isOpen =
        action?.payload !== undefined ? action?.payload : !state.isOpen;
    },
    updateBreadcrums: (state, action) => {
      state.breadcrums = action.payload;
    },
    upgradeActiveStepCount: (state, action) => {
      state.productTourActiveStep = action.payload;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
