import { Fragment, useEffect, useState } from "react";
import {
  IconButton,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  Typography,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { useSelector } from "react-redux";

import ChartExpandView from "components/common/ChartExpandView/ChartExpandView";
import styles from "pages/ChatPopup/ChatPopup.module.css";
import restClient from "restClient";
import convertDate from "utilities/dateFormats/converters/convertDate";

const ChatModal = ({
  toggleModal,
  loading,
  setLoading,
  chatHistory,
  setChatHistory,
}) => {
  const [queString, setQueString] = useState("");
  const orgid = useSelector((state) => state.orgData.orgid);

  const getQueId = async () => {
    const payload = {
      orgid: orgid,
      text: queString,
    };

    const response = await restClient({
      method: "POST",
      url: `/interact/chat/query`,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      data: payload,
    });
    if (response.status === 200) {
      return response.data?.qid;
    }
    return null;
  };

  const getQueIdResponse = async (queId) => {
    const response = await restClient({
      method: "GET",
      url: `interact/chat/answer?orgid=${orgid}&qid=${queId}`,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    if (response.status === 200) {
      return response.data?.answer;
    }
  };

  const fetchChatList = async () => {
    const response = await restClient({
      method: "GET",
      url: `interact/chat/list?orgid=${orgid}`,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    });
    if (response?.status === 200) {
      setChatHistory([...response.data.history]);
    }
  };

  const onSubmit = async () => {
    const queId = await getQueId();
    setLoading(true);
    setChatHistory([
      {
        qid: queId,
        text: queString,
      },
      ...chatHistory,
    ]);

    setQueString("");

    if (queId) {
      let response;

      const pollForResponse = () => {
        setTimeout(async () => {
          response = await getQueIdResponse(queId);
          if (!response) {
            pollForResponse(); // Call the function again if response is not valid
          } else {
            fetchChatList();
            setLoading(false);
          }
        }, 3000);
      };

      pollForResponse();
    }
  };

  useEffect(() => {
    fetchChatList();
  }, []);

  return (
    <ChartExpandView onClose={toggleModal}>
      <div className={styles.formWrapper}>
        {/* Header */}
        <div className={styles.header}>
          <h3 className={styles.modalTitle}>{/* Add title here */}</h3>
          <Tooltip title="Collapse Modal" arrow>
            <img
              className={styles.collapseIcon}
              src="/icons/BarChartControls/Collapse.svg"
              alt="collapse modal"
              onClick={toggleModal}
            />
          </Tooltip>
        </div>

        {/* Message window */}
        <div className={styles.chatContainer}>
          {chatHistory.length > 0
            ? chatHistory.map((chatData) => (
                <Fragment key={chatData.qid}>
                  {chatData.response && (
                    <div
                      className={`${styles.chatMessage} ${styles.receiverMessage}`}
                    >
                      <p className={styles.messageText}>{chatData.response}</p>
                      <span className={styles.messageTimestamp}>
                        {convertDate(chatData.time)}
                      </span>
                    </div>
                  )}
                  <div
                    className={`${styles.chatMessage} ${styles.senderMessage}`}
                  >
                    <p className={styles.senderMessageText}>{chatData.text}</p>
                    {/* <span
                    className={`${styles.messageTimestamp} ${styles.senderMessageTimestamp}`}
                  >
                    {convertDate(chatData.time)}
                  </span> */}
                    {/*  */}
                  </div>
                </Fragment>
              ))
            : !loading && (
                <div className={styles.loaderWrapper}>
                  <div style={{ marginTop: "6%" }}></div>
                  <Typography variant="label2">
                    Have a question about your cloud spend? Feel free to ask
                    here!
                  </Typography>
                  <div style={{ marginTop: "2rem" }}></div>
                  <Typography variant="label5" style={{ marginTop: "2rem" }}>
                    Our team is ready to assist you with any queries you may
                    have.
                  </Typography>
                </div>
              )}
        </div>
        {loading && (
          <div className={styles.loaderWrapper}>
            <img src="/icons/Loader/Loader_08_200-200.gif" alt="loading" />
          </div>
        )}
      </div>

      {/* Input box */}
      <OutlinedInput
        type="text"
        className={styles.chatInput}
        color="primary"
        value={queString}
        onKeyDown={(e) =>
          e.key === "Enter" && queString.length && !loading && onSubmit()
        }
        onChange={(e) => setQueString(e.target.value)}
        disabled={loading}
        placeholder={`When asking questions, try to enclose important details or keywords within quotes. This will help us to generate better output for your question.`}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip title="Send" arrow>
              <IconButton
                color="primary"
                onClick={onSubmit}
                edge="end"
                disabled={!queString.length}
              >
                <Send />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
    </ChartExpandView>
  );
};

export default ChatModal;

// No data fount text
